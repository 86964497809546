.fx_oc-compare-page-range {
    .ant-select {
        height: 22px;
        line-height: 22px;
        width: 147px;
        margin-left: 10px;
    }
}

.fx_oc-compare-page-range-to-label {
    display: inline-block;
    
    width: 131px;
    
    text-align: right;
}

.fx_oc-compare-page-range-from-label {
    display: inline-block;
    
    width: 92px;
    text-align: right;
}