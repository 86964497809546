$dropdown-height: 24px;

.fx_oc-page-number-dropdown {
    border-radius: 4px;
    width: 53px;
    height: $dropdown-height;
    line-height: $dropdown-height;

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 6px;
    }
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    &.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input,
    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        height: 100%;
        line-height: inherit;
    }
    .ant-select-arrow {
        right: 6px;
        &, .anticon {
            pointer-events: none;
        }
    }
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: white;
    }
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 10px;
    }
}
.fx_oc-page-number-dropdown-list {
    .ant-select-item {
        padding: 5px 0 0 12px;
    }
}
